import React from 'react';
import PrivateRoutes from './routes/PrivateRoutes';

const App = () => {
  return (
    <div className="w-full">
        <PrivateRoutes />
    </div>
  );
}

export default App;
