// src/App.js or wherever you are defining your routes

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Contact from '../pages/Contact';
import Courses from '../pages/Courses';
import Gallery from '../pages/Gallery';
import Home from '../pages/Home';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const App = () => {
  return (
    <Router>
      <Navbar />
      <div className='bg-slate-800 text-white'>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/courses" element={<Courses />} />
          <Route path="/gallery" element={<Gallery />} />
          {/* Catch-all route to handle 404 */}
          <Route path="*" element={<Home />} /> {/* Or a custom 404 component */}
        </Routes>
      </div>
      <Footer className='bg-customPurple' />
    </Router>
  );
}

export default App;
