import React, { useState } from 'react';
import { FaChevronDown, FaTimes } from 'react-icons/fa'; // Import Font Awesome icons

const FAQSection = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqs = [
    {
      question: 'Post Graduate Diploma in Computer Applications (PGDCA)',
      answer: 'The Post Graduate Diploma in Computer Applications (PGDCA) covers computer fundamentals, operating systems, office automation packages, internet applications, and smartphone usage. It includes basics of computer organization, RDBMS concepts with MS Access and SQL Server, and object-oriented programming with C++ and Core Java. The course also covers data communications, networking, web technologies (HTML, CSS, JavaScript), software engineering, project management, and communication and employability skills.',
    },
    {
      question: 'Diploma in Computer Applications (DCA)',
      answer: 'The Diploma in Computer Applications (DCA) covers computer fundamentals, operating systems, office automation packages, and connecting to the world. It includes smartphone usage, introduction to database management systems with MS Access and SQL Server, and basics of web page designing. The course also includes programming with C, introduction to object-oriented programming with C++, and GUI programming with Visual Basic. Additionally, it covers communication and employability skills, network concepts, JavaScript, and fundamentals of software engineering.',
    },
    {
      question: 'Diploma in Computer Software Plus (DCSP)',
      answer: 'The Diploma in Computer Software Plus (DCSP) covers computer fundamentals, operating systems, office automation packages, and connecting to the world. It includes smartphone usage, introduction to database management systems with MS Access and SQL Server, and basics of web page designing. The course also covers programming with C, introduction to object-oriented programming with C++, and GUI programming with Visual Basic. Additionally, it includes communication and employability skills, network concepts, JavaScript, fundamentals of software engineering, IT skills, and an elective in .NET technology or Java technology.',
    },
    {
      question: 'Diploma in Computer Aided Accounting (DCAA)',
      answer: 'The Diploma in Computer Aided Accounting (DCAA) covers computer fundamentals, operating systems, office automation packages, and connecting to the world. It includes smartphone usage, manual accounting, and computerized accounting with Tally. The course also covers company accounts, financial statement analysis, introduction to financial markets, and income tax and GST return. Additionally, it includes communication and employability skills.',
    },
    {
      question: 'Diploma in Designing and Publishing (DDP)',
      answer: 'The Diploma in Designing and Publishing (DDP) covers computer fundamentals, operating systems, office automation packages, and connecting to the world. It includes smartphone usage, basics of web page designing, and desktop publishing tools. Additionally, it covers communication and employability skills.',
    },
    {
      question: 'Diploma in Web Technology (DWT) ',
      answer: 'The Diploma in Web Technology (DWT) covers computer fundamentals, operating systems, office automation packages, and connecting to the world. It includes smartphone usage, introduction to database management systems with MS Access and SQL Server, and basics of web page designing. The course also covers web page authoring tools, server-side programming, and communication and employability skills.',
    },
    {
      question: 'Certificate Course in Digital Literacy (CCDL)',
      answer: 'The Certificate Course in Digital Literacy (CCDL) covers computer fundamentals, operating systems, office automation packages, and connecting to the world. It includes smartphone usage, introduction to database management systems with MS Access and SQL Server, and basics of web page designing. Additionally, it covers communication and employability skills.',
    },
    {
      question: 'Certificate Course in Computer Basics and Accounting (CCCBA)',
      answer: 'The Certificate Course in Computer Basics and Accounting (CCCBA) covers computer fundamentals, operating systems, office automation packages, and connecting to the world. It includes smartphone usage, introduction to database management systems with MS Access and SQL Server, and accounting basics.',
    },
    {
      question: 'Certificate Course in Web Designing and Multimedia (CCWDM)',
      answer: 'The Certificate Course in Web Designing and Multimedia (CCWDM) covers computer fundamentals, operating systems, and connecting to the world. It includes smartphone usage, basics of web page designing, and multimedia software tools. Additionally, it covers communication and employability skills.',
    },
    {
      question: 'Foundation Course in Computer Basics (FCCB)',
      answer: 'The Foundation Course in Computer Basics (FCCB) covers computer fundamentals, operating systems, office automation packages, and typing and basic communication skills. It also includes connecting to the world and smartphone usage.',
    },
    {
      question: 'Programming with Python',
      answer: 'Programming with Python covers an introduction to Python, control statements, data structures, and functions. It also includes file handling, Python modules and packages, object-oriented programming, exception handling, regular expressions, and database management.',
    },
    {
      question: 'AutoCAD (Civil, Electrical, and Mechanical)',
      answer: 'AutoCAD (Civil, Electrical, and Mechanical) covers the creation and manipulation of 2D and 3D structures. The course focuses on specialized techniques and tools tailored to each engineering discipline, providing comprehensive training in designing detailed plans and models for civil, electrical, and mechanical projects.',
    },
    {
      question: 'Digital Marketing Assistant',
      answer: 'Digital Marketing Assistant covers an introduction to digital marketing and website creation using HTML, CSS, JS, and WordPress. It includes search engine optimization (SEO), search engine marketing (SEM), social media marketing (SMM), online marketing, lead generation, and Google Analytics. The course also covers Google AdSense, affiliate marketing, employability skills, and on-the-job training (OJT).',
    },
    {
      question: 'C Language',
      answer: 'C Language covers an introduction to C, Features and Applications of C, tokens, Data Types, Variables, Format Specifier, Escape Sequence, Operators, Control Flow Statements, Continue Statement, Loops, Array, Functions, Recursion, Macros. ',
    },
    {
      question: 'C Plus Plus (CPP)',
      answer: 'C Plus Plus covers features of Object Oriented Programming, Applications of C++, Tokens, Data Types, Operators, Control Statements, Selection Statement, Continue Statement, goto Statement, Loops, Array, Function, Recursion, Pointer, Macros, C++ with OOPS, Contructors, Destructor, Friend Function.',
    },
  ];

  return (
    <>


<section className="bg-blue-100 dark:bg-blue-100 flex justify-center">
  <div className="py-2 px-4 max-w-screen-xl lg:py-16 lg:px-6">
    <div className="text-gray-500 sm:text-lg dark:text-gray-400">
      <div className="section-title text-center mb-6" data-aos="fade-up">
        <h2 className="text-3xl font-bold text-black">
          Hartron Approved Courses <br/>
          Carving Human Resource with Bright Opportunities
        </h2>
      </div>
      <p className="font-medium text-black text-justify">
        Hartron Skill Center is the first franchisee of Haryana State Electronics Development Corporation (HARTRON) Chandigarh, a nodal agency of Government of Haryana, supporting in supplying manpower in Government Sectors. The center is providing training in various Hardware, Software and Accounting Courses approved and authorised by Hartron. These courses are of duration of one year, six months and three months and are named as Hartron Approved Courses. Each student in computer training session is ensured with individual attention of the faculty and provision of proper course material. Courses offered by us are:
      </p>
    </div>
  </div>
</section>







<section id="faq" className="faq py-4 px-2 md:py-6 md:px-8 bg-blue-100">
        <div className="container mx-auto px-4">
          <ul className="faq-list space-y-4">
            {faqs.map((faq, index) => (
              <li key={index} data-aos="fade-up" className="bg-white p-4 rounded shadow">
                <div className="flex justify-between items-center">
                  <span className="text-base md:text-lg font-medium text-gray-700 flex-1">
                    {faq.question}
                  </span>
                  <button
                    className="ml-2 text-gray-700 hover:text-gray-900 focus:outline-none"
                    onClick={() => toggleFAQ(index)}
                  >
                    {activeIndex === index ? (
                      <FaTimes className="w-5 h-5" />
                    ) : (
                      <FaChevronDown className="w-5 h-5" />
                    )}
                  </button>
                </div>
                <div className={`mt-2 ${activeIndex === index ? 'block' : 'hidden'}`}>
                  <p className="text-gray-600 text-sm md:text-base">{faq.answer}</p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </section>


    </>
  );
};

export default FAQSection;
