import React from 'react'

const Footer = () => {
  return (
    <div>

<div className="px-4 pt-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
      <div className="grid gap-10 row-gap-6 mb-8 sm:grid-cols-2 lg:grid-cols-4">
        <div className="sm:col-span-2">
          <a
            href="/"
            aria-label="Go home"
            title="Company"
            className="inline-flex items-center"
          >
            <svg
              className="w-8 text-deep-purple-accent-400"
              viewBox="0 0 24 24"
              strokeLinejoin="round"
              strokeWidth="2"
              strokeLinecap="round"
              strokeMiterlimit="10"
              stroke="currentColor"
              fill="none"
            >
              <rect x="3" y="1" width="7" height="12" />
              <rect x="3" y="17" width="7" height="6" />
              <rect x="14" y="1" width="7" height="6" />
              <rect x="14" y="11" width="7" height="12" />
            </svg>
            <span className="ml-2 text-xl font-bold tracking-wide text-gray-800 uppercase">
              Hartron Skill Centre
            </span>
          </a>
          <div className="mt-6 lg:max-w-sm">
            <p className="text-l text-gray-800">
            Hartron Computer Institute offers practical, industry-focused computer education to equip students with essential skills for the digital age. We prioritize hands-on learning and quality education to ensure comprehensive understanding of computer technologies and applications.
            </p>
          </div>
        </div>
        <div className="space-y-2 text-sm">
          <p className="text-base font-bold tracking-wide text-gray-900">
            Contacts
          </p>
          <div className="flex">
            <p className="mr-1 text-gray-800">Phone:</p>
            <a
              href="tel:+91-8929411222"
              aria-label="Our phone"
              title="Our phone"
              className="transition-colors duration-300 text-deep-purple-accent-400 hover:text-deep-purple-800"
            >
              +91-8929411222
            </a>
          </div>
          <div className="flex">
            <p className="mr-1 text-gray-800">Email:</p>
            <a
              href="mailto:diit.rtk@gmail.com"
              aria-label="Our email"
              title="Our email"
              className="transition-colors duration-300 text-deep-purple-accent-400 hover:text-deep-purple-800"
            >
              diit.rtk@gmail.com
            </a>
          </div>
          <div className="flex">
            <p className="mr-1 text-gray-800">Address:</p>
            <a
              href="https://maps.app.goo.gl/krCdVPQ5aBAFBCn97"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Our address"
              title="Our address"
              className="transition-colors duration-300 text-deep-purple-accent-400 hover:text-deep-purple-800"
            >
              Hartron Skill Centre, Above IDBI Bank, Opposite Neki Ram College, Near Power House Chowk, Rohtak
            </a>
          </div>
        </div>
        <div>
          <span className="text-base font-bold tracking-wide text-gray-900">
            Social
          </span>
          <div className="flex items-center mt-1 space-x-3">
            <a
              href="https://youtube.com/@hartronguruji?feature=shared"
              className="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-400"
            >
              <svg viewBox="0 0 24 24" fill="currentColor" className="h-5">
                <path d="M23.498,6.186c-0.272-1.03-1.09-1.838-2.12-2.11C19.781,3.6,12,3.6,12,3.6s-7.781,0-9.378,0.475c-1.03,0.272-1.848,1.08-2.12,2.11C0,7.779,0,12,0,12s0,4.221,0.502,5.814c0.272,1.03,1.09,1.838,2.12,2.11C4.219,20.4,12,20.4,12,20.4s7.781,0,9.378-0.475c1.03-0.272,1.848-1.08,2.12-2.11C24,16.221,24,12,24,12S24,7.779,23.498,6.186z M9.748,15.538v-7.07l6.936,3.535L9.748,15.538z" />
              </svg>
            </a>
            <a
              href="https://instagram.com/hartron_rohtak_?igshid=OGQ5ZDc2ODk2ZA=="
              className="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-400"
            >
              <svg viewBox="0 0 30 30" fill="currentColor" className="h-6">
                <circle cx="15" cy="15" r="4" />
                <path d="M19.999,3h-10C6.14,3,3,6.141,3,10.001v10C3,23.86,6.141,27,10.001,27h10C23.86,27,27,23.859,27,19.999v-10   C27,6.14,23.859,3,19.999,3z M15,21c-3.309,0-6-2.691-6-6s2.691-6,6-6s6,2.691,6,6S18.309,21,15,21z M22,9c-0.552,0-1-0.448-1-1   c0-0.552,0.448-1,1-1s1,0.448,1,1C23,8.552,22.552,9,22,9z" />
              </svg>
            </a>
            <a
              href="https://www.facebook.com/HartronSkillCenterRohtak"
              className="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-400"
            >
              <svg viewBox="0 0 24 24" fill="currentColor" className="h-5">
                <path d="M22,0H2C0.895,0,0,0.895,0,2v20c0,1.105,0.895,2,2,2h11v-9h-3v-4h3V8.413c0-3.1,1.893-4.788,4.659-4.788 c1.325,0,2.463,0.099,2.795,0.143v3.24l-1.918,0.001c-1.504,0-1.795,0.715-1.795,1.763V11h4.44l-1,4h-3.44v9H22c1.105,0,2-0.895,2-2 V2C24,0.895,23.105,0,22,0z" />
              </svg>
            </a>
          </div>
          <p className="mt-4 text-sm text-black">
          Follow us on social media for the latest updates, news, and exclusive content.
          </p>
        </div>
      </div>
      <div className="flex flex-col-reverse justify-between pt-5 pb-10 border-t lg:flex-row">
        <p className="text-l text-black">
          © Copyright 2024 Hartron Skill Centre. All rights reserved.
        </p>
        <ul className="flex flex-col mb-3 space-y-2 lg:mb-0 sm:space-y-0 sm:space-x-5 sm:flex-row">
          <li>
            <a
              href="https://maps.app.goo.gl/Q4RbA6Fh8wpvuZzr8"
              className="text-l text-black transition-colors duration-300 hover:text-deep-purple-accent-400"
            >
              Designed & Developed by: Shivam Bhardwaj
            </a>
          </li>
          
        </ul>
      </div>
    </div>


    </div>
  )
}

export default Footer