import React from 'react';

const Contact = () => {
  return (
    <div className="py-8 bg-blue-100">
      <h2 className="text-gray-800 text-3xl font-extrabold text-center">Get In Touch</h2>
      <div className="max-w-6xl bg-blue-200 mx-auto grid md:grid-cols-2 gap-16 items-center relative overflow-hidden p-8 shadow-[0_2px_10px_-3px_rgba(6,81,237,0.3)] rounded-3xl mt-4 font-[sans-serif]">
        <div className="bg-blue-300 p-8 rounded-3xl">
          
          <form className="mt-8 space-y-4" action='https://formspree.io/f/xblrjpjd' method="POST">
            <input
              type="text"
              id="name"
              name='name'
              placeholder="Full Name"
              className="px-2 py-3 bg-white w-full text-gray-800 text-sm border-b border-gray-300 focus:border-blue-600 outline-none"
            />
            
            <input
              type="number"
              id="Phone_Number"
              name="Phone_Number"
              placeholder="Phone No."
              className="px-2 py-3 bg-white w-full text-gray-800 text-sm border-b border-gray-300 focus:border-blue-600 outline-none"
            />
            <input
              type="email"
              id="Email_ID"
              name="Email_ID"
              placeholder="Email"
              className="px-2 py-3 bg-white w-full text-gray-800 text-sm border-b border-gray-300 focus:border-blue-600 outline-none"
            />
            <textarea
              placeholder="Write Message"
              id="Message"
              name="Message"
              rows="5"
              className="px-2 pt-3 bg-white w-full text-gray-800 text-sm border-b border-gray-300 focus:border-blue-600 outline-none"
              defaultValue={""}
            />
            <button
              type="submit"
              className="mt-8 flex items-center justify-center text-sm w-full rounded-md px-6 py-3 bg-blue-600 hover:bg-blue-700 text-white"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16px"
                height="16px"
                fill="#fff"
                className="mr-2"
                viewBox="0 0 548.244 548.244"
              >
                <path
                  fillRule="evenodd"
                  d="M392.19 156.054 211.268 281.667 22.032 218.58C8.823 214.168-.076 201.775 0 187.852c.077-13.923 9.078-26.24 22.338-30.498L506.15 1.549c11.5-3.697 24.123-.663 32.666 7.88 8.542 8.543 11.577 21.165 7.879 32.666L390.89 525.906c-4.258 13.26-16.575 22.261-30.498 22.338-13.923.076-26.316-8.823-30.728-22.032l-63.393-190.153z"
                  clipRule="evenodd"
                  data-original="#000000"
                />
              </svg>
              Send Message
            </button>
          </form>
          
        </div>
        <div className="relative" style={{ paddingBottom: '56.25%', maxWidth: '800px', maxHeight: '600px' }}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d447154.18997282174!2d76.612974!3d28.886694!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d85deb123dd6b%3A0xa3d431207c77bf24!2sHartron%20skill%20center!5e0!3m2!1sen!2sin!4v1720057681655!5m2!1sen!2sin"
            title="Map"
            className="absolute inset-0 w-full h-full rounded-2xl"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="fast"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default Contact;
