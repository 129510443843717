import React, { useState } from 'react';
import vid from "../assets/video/introvid.mp4";
import ten from "../assets/images/10.jpg";
import eleven from "../assets/images/11.jpg";
import twelve from "../assets/images/12.jpg";
import thirteen from "../assets/images/13.jpg";
import fourteen from "../assets/images/14.jpg";
import fifteen from "../assets/images/15.jpg";
import sixteen from "../assets/images/16.jpg";
import seventeen from "../assets/images/17.jpg";
import eighteen from "../assets/images/18.jpg";
import nineteen from "../assets/images/19.jpg";
import twenty from "../assets/images/20.jpg";
import twentyone from "../assets/images/21.jpg";
import twentytwo from "../assets/images/22.jpg";
import twentythree from "../assets/images/23.jpg";
import twentyfour from "../assets/images/24.jpg";
import twentyfive from "../assets/images/25.jpg";
import twentysix from "../assets/images/26.jpg";
import twentyseven from "../assets/images/27.jpg";

const FeaturedImageGallery = () => {
  const data = [
    {
      imgelink: ten, 
    },
    {
      imgelink: eleven,
    },
    {
      imgelink: twelve,
    },
    {
      imgelink: thirteen,
    },
    {
      imgelink: fourteen,
    },
    {
      imgelink: fifteen,
    },
    {
      imgelink: sixteen,
    },
    {
      imgelink: seventeen,
    },
    {
      imgelink: eighteen,
    },
    {
      imgelink: nineteen,
    },
    {
      imgelink: twenty,
    },
    {
      imgelink: twentyone,
    },
    {
      imgelink: twentytwo,
    },
    {
      imgelink: twentythree,
    },
    {
      imgelink: twentyfour,
    },
    {
      imgelink: twentyfive,
    },
    {
      imgelink: twentysix,
    },
    {
      imgelink: twentyseven,
    },
  ];

  const [activeImageIndex, setActiveImageIndex] = useState(null);

  const openImage = (index) => {
    setActiveImageIndex(index);
  };

  const closeImage = () => {
    setActiveImageIndex(null);
  };

  const prevImage = () => {
    setActiveImageIndex((prevIndex) =>
      prevIndex === 0 ? data.length - 1 : prevIndex - 1
    );
  };

  const nextImage = () => {
    setActiveImageIndex((prevIndex) =>
      prevIndex === data.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <>
      <div className="flex justify-center items-center min-h-screen p-4 bg-blue-100">
        <video
          className="w-full max-w-5xl max-h-xl rounded-lg"
          controls
          autoPlay
          muted
        >
          <source src={vid} type="video/mp4" />
        </video>
      </div>

      <div className="flex justify-center items-center p-4 bg-blue-100">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4 p-4">
          {data.map(({ imgelink }, index) => (
            <div key={index}>
              <img
                onClick={() => openImage(index)}
                src={imgelink}
                className="w-full h-64 cursor-pointer rounded-lg object-cover"
                alt={`gallery-image-${index}`}
              />
            </div>
          ))}
        </div>

        {activeImageIndex !== null && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50 p-4">
            <button
              onClick={closeImage}
              className="absolute top-4 right-4 text-black text-xxl h-10 w-10 bg-white rounded-full"
            >
              &times;
            </button>
            <button
              onClick={prevImage}
              className="absolute left-4 text-black text-bold text-xxl rounded-full h-10 w-10 bg-white"
            >
              &lt;
            </button>
            <img
              src={data[activeImageIndex].imgelink}
              className="max-h-full max-w-full"
              alt={`gallery-image-${activeImageIndex}`}
            />
            <button
              onClick={nextImage}
              className="absolute right-4 text-black text-bold text-xxl bg-white rounded-full h-10 w-10"
            >
              &gt;
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default FeaturedImageGallery;
