import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa"; 

import logo from "../assets/images/logo.png";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLinkClick = () => {
    setIsMenuOpen(false);
  };

  return (
    <div className="bg-white p-5">
      <div className="bg-white mx-auto max-w-[1560px] border-black flex flex-row justify-between items-center px-2 sm:px-10 py-4 h-20">
        <div>
          <div className="text-black">
            <img src={logo} className="h-20 w-200" />
          </div>
        </div>
        <div className="block sm:hidden">
          <button onClick={toggleMenu} className="text-black text-3xl">
            {isMenuOpen ? <FaTimes /> : <FaBars />}
          </button>
        </div>
        <div
          className={`${
            isMenuOpen ? "flex" : "hidden"
          } sm:flex sm:flex-row gap-10 p-5 absolute sm:static bg-white sm:bg-transparent left-0 top-[6rem] w-full p-5 sm:w-auto items-center p-5 z-50`}
        >
          <ul className="flex flex-col sm:flex-row gap-10 text-lg bg-white sm:bg-transparent w-full sm:w-auto items-center sm:items-start text-center py-4 sm:py-0">
            <li>
              <Link to="/" className="text-black cursor-pointer hover:text-gray-300" onClick={handleLinkClick}>
                Home
              </Link>
            </li>
            <li>
              <Link to="/courses" className="text-black cursor-pointer hover:text-gray-300" onClick={handleLinkClick}>
                Courses
              </Link>
            </li>
            <li>
              <Link to="/gallery" className="text-black cursor-pointer hover:text-gray-300" onClick={handleLinkClick}>
                Gallery
              </Link>
            </li>
            <li>
              <Link to="/contact" className="text-black cursor-pointer hover:text-gray-300" onClick={handleLinkClick}>
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
