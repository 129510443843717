import React from 'react'
import director from "../assets/images/md-1.jpg"
import dca from "../assets/images/dca.JPG"
import dcaa from "../assets/images/dcaa.JPG"
import pgdca from "../assets/images/pgdca.JPG"


const Home = () => {
  return (
    <>
    
      <div className="relative isolate flex items-center justify-center px-6 lg:px-8 bg-blue-100 min-h-screen">
  <div className="mx-auto max-w-3xl text-center">
    <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold tracking-tight text-gray-900">
      HARTRON SKILL CENTRE,<br /> POWER HOUSE,<br /> ROHTAK
    </h1>
    <p className="mt-6 text-xl leading-8 text-gray-600">
      Hartron Skill Centre, the first HARTRON franchisee in Rohtak, provides top-quality computer training and supports government sectors with skilled manpower, enhancing Haryana's workforce.
    </p>
    <div className="mt-10 flex items-center justify-center gap-x-6">
      <a
        href="#features"
        className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        Get started
      </a>
    </div>
  </div>
</div>

      <div className="bg-blue-100">
      <section
        id="features"
        className="relative block px-6 py-10 md:py-20 md:px-10 border-t border-b border-transparent bg-neutral-200/30"
      >
        <div className="relative mx-auto max-w-5xl text-center">
          <span className="text-black my-3 flex items-center justify-center font-medium uppercase tracking-wider">
            Why choose us
          </span>
          <h2 className="block w-full bg-gradient-to-b from-white to-gray-600 bg-clip-text font-bold text-black text-3xl sm:text-4xl">
          Why Choose Our Institution?
          </h2>
          <p className="mx-auto my-4 w-full max-w-xl bg-transparent text-center font-medium leading-relaxed tracking-wide text-gray-600">
          Explore limitless customization possibilities tailored to your learning needs. No technical background required, our expert teachers make mastering tech skills accessible and enjoyable.
          </p>
        </div>
        <div className="relative mx-auto max-w-7xl z-10 grid grid-cols-1 gap-10 pt-14 sm:grid-cols-2 lg:grid-cols-3">
          <div className="rounded-md border border-neutral-300 bg-neutral-200 p-8 text-center shadow">
            <div
              className="button-text mx-auto flex h-12 w-12 items-center justify-center rounded-md border"
              style={{
                backgroundImage:
                  "linear-gradient(rgb(80, 70, 229) 0%, rgb(43, 49, 203) 100%)",
                borderColor: "rgb(93, 79, 240)"
              }}
            >
              <svg
  xmlns="http://www.w3.org/2000/svg"
  className="icon icon-tabler icon-tabler-computer-education"
  width={24}
  height={24}
  viewBox="0 0 24 24"
  strokeWidth={2}
  stroke="currentColor"
  fill="none"
  strokeLinecap="round"
  strokeLinejoin="round"
>
  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
  <rect x="4" y="4" width="16" height="12" rx="1" />
  <path d="M4 18h16" />
  <path d="M8 22h8" />
  <path d="M10 18v4" />
  <path d="M14 18v4" />
  <path d="M12 10l4 -2" />
  <path d="M12 6l-4 2" />
  <path d="M12 6v4" />
</svg>
            </div>
            <h3 className="mt-6 text-black font-bold">Teaching Environment</h3>
            <p className="my-4 mb-0 font-normal leading-relaxed tracking-wide text-black">
            All our faculty staffs are well trained and has broad knowledge in their specialized subjects, thus students get quality training as well as good working skills.
            </p>
          </div>
          <div className="rounded-md border border-neutral-300 bg-neutral-200 p-8 text-center shadow">
            <div
              className="button-text mx-auto flex h-12 w-12 items-center justify-center rounded-md border"
              style={{
                backgroundImage:
                  "linear-gradient(rgb(80, 70, 229) 0%, rgb(43, 49, 203) 100%)",
                borderColor: "rgb(93, 79, 240)"
              }}
            >
              <svg
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon-tabler icon-tabler-buildings"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <rect x="3" y="12" width="6" height="8" rx="1" />
      <line x1="6" y1="12" x2="6" y2="20" />
      <line x1="8" y1="12" x2="8" y2="20" />
      <rect x="9" y="3" width="12" height="17" rx="1" />
      <line x1="12" y1="3" x2="12" y2="20" />
      <line x1="14" y1="3" x2="14" y2="20" />
      <line x1="16" y1="3" x2="16" y2="20" />
      <line x1="18" y1="3" x2="18" y2="20" />
      <line x1="20" y1="3" x2="20" y2="20" />
      <line x1="21" y1="3" x2="21" y2="20" />
    </svg>
            </div>
            <h3 className="mt-6 text-black font-bold">Infrastructure</h3>
            <p className="my-4 mb-0 font-normal leading-relaxed tracking-wide text-black">
            State of the art Computer Labs equipped with latest Hardware & Software to get future proof knowledge and to understand the latest technologies.
            </p>
          </div>
          <div className="rounded-md border border-neutral-300 bg-neutral-200 p-8 text-center shadow">
            <div
              className="button-text mx-auto flex h-12 w-12 items-center justify-center rounded-md border"
              style={{
                backgroundImage:
                  "linear-gradient(rgb(80, 70, 229) 0%, rgb(43, 49, 203) 100%)",
                borderColor: "rgb(93, 79, 240)"
              }}
            >
      <svg
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon-tabler icon-tabler-desktop"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <rect x="4" y="4" width="16" height="12" rx="1" />
      <path d="M16 7.5v5.5" />
      <path d="M12 18h4" />
      <path d="M14 21h-4" />
      <path d="M8 18v-11" />
      <path d="M8 5h8" />
    </svg>
            </div>
            <h3 className="mt-6 text-black font-bold">Excellent Faculties</h3>
            <p className="my-4 mb-0 font-normal leading-relaxed tracking-wide text-black">
            All our faculty staffs are well trained and has broad knowledge in their specialized subjects, thus students get quality training as well as good working skills.
            </p>
          </div>
        </div>
        <div
          className="absolute bottom-0 left-0 z-0 h-1/3 w-full border-b"
          style={{
            backgroundImage:
              "linear-gradient(to right top, rgba(79, 70, 229, 0.2) 0%, transparent 50%, transparent 100%)",
            borderColor: "rgba(92, 79, 240, 0.2)"
          }}
        ></div>
        <div
          className="absolute bottom-0 right-0 z-0 h-1/3 w-full"
          style={{
            backgroundImage:
              "linear-gradient(to left top, rgba(220, 38, 38, 0.2) 0%, transparent 50%, transparent 100%)",
            borderColor: "rgba(92, 79, 240, 0.2)"
          }}
        ></div>
      </section>
    </div>




    <div id="about" className="relative bg-white overflow-hidden mt-16">
  <div className="max-w-7xl mx-auto">
    <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
      <svg
        className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2"
        fill="currentColor"
        viewBox="0 0 100 100"
        preserveAspectRatio="none"
        aria-hidden="true"
      >
        <polygon points="50,0 100,0 50,100 0,100" />
      </svg>
      <div className="pt-1" />
      <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
        <div className="sm:text-center lg:text-left">
          <h2 className="my-6 text-2xl tracking-tight font-extrabold text-gray-900 sm:text-3xl md:text-4xl">
            Message from Director
          </h2>
          <p className="text-black">
          At Hartron Skill Center Power House Rohtak, we prioritize creating an exceptional study environment. In today's era, information stands as a formidable asset, with timely access to accurate data being crucial. Information Technology (IT) serves as both a tool and a philosophy, enabling the effective flow of this data. HARTRON is committed to equipping individuals with the right tools, techniques, and methodologies to actively contribute to and enhance Haryana. <br /> <br /> (Dr. Ashutosh Kaushik)
          </p>
        </div>
      </main>
    </div>
  </div>
  <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
    <img
      className="h-56 w-full object-cover object-top sm:h-72 md:h-96 lg:w-full lg:h-full"
      src={director}
      alt=""
    />
    
  </div>
</div>





<div className="relative bg-blue-100 px-6 pt-16 pb-20 lg:px-8 lg:pt-24 lg:pb-28">
  <div className="absolute inset-0">
    <div className="h-1/3 bg-white-100 sm:h-2/3" />
  </div>
  <div className="relative mx-auto max-w-7xl">
    <div className="text-center">
      <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
        Our Popular courses
      </h2>
      <p className="mx-auto mt-3 max-w-2xl text-xl text-gray-500 sm:mt-4">
      Our popular courses offer comprehensive training, equipping students with essential skills for today's job market
      </p>
    </div>
    <div className="mx-auto mt-12 grid max-w-lg gap-5 lg:max-w-none lg:grid-cols-3">
      <div className="flex flex-col overflow-hidden rounded-lg shadow-lg">
        <div className="flex-shrink-0">
          <img
            className="h-48 w-full object-cover"
            src={dca}
            alt=""
          />
        </div>
        <div className="flex flex-1 flex-col justify-between bg-white p-6">
          <div className="flex-1">
           
            <p className="mt-2 block">
              <p className="text-xl font-semibold text-gray-900">
                Diploma in Computer Application <br /> (DCA)
              </p>
              <p className="mt-3 text-base text-gray-500">
              After completing the DCA (Diploma in Computer Application), you are eligible to apply for: <br />
              - Computer Operator positions<br />
              - Data Entry jobs<br />
              - Technical Support roles<br />
              - Roles in government offices<br />
              - IT Help Desk jobs<br />
              - E-Governance services<br />
              </p>
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-col overflow-hidden rounded-lg shadow-lg">
        <div className="flex-shrink-0">
          <img
            className="h-48 w-full object-cover"
            src={dcaa}
            alt=""
          />
        </div>
        <div className="flex flex-1 flex-col justify-between bg-white p-6">
          <div className="flex-1">
            <a href="#" className="mt-2 block">
              <p className="text-xl font-semibold text-gray-900">
              Diploma in Computer Aided Accounting <br /> (DCAA)
              </p>
              <p className="mt-3 text-base text-gray-500">
              After completing the DCAA (Diploma in Computer Aided Accounting), you are eligible to apply for: <br />
              - Junior Accountant positions<br/>
              - Accounts Assistant roles<br/>
              - Billing Clerk jobs<br/>
              - Payroll Assistant positions<br/>
              - Financial Data Entry jobs<br/>
              - Bookkeeping roles<br/>
              </p>
            </a>
          </div>
        </div>
      </div>
      <div className="flex flex-col overflow-hidden rounded-lg shadow-lg">
        <div className="flex-shrink-0">
          <img
            className="h-48 w-full object-cover"
            src={pgdca}
            alt=""
          />
        </div>
        <div className="flex flex-1 flex-col justify-between bg-white p-6">
          <div className="flex-1">
            <a href="#" className="mt-2 block">
              <p className="text-xl font-semibold text-gray-900">
                Post-Graduate Diploma in Computer Application (PGDCA)
              </p>
              <p className="mt-3 text-base text-gray-500">
              After completing the PGDCA (Post Graduate Diploma in Computer Applications), you are eligible to apply for:<br/>
              - Software Developer positions<br/>
              - System Analyst roles<br/>
              - IT Consultant jobs<br/>
              - Database Administrator positions<br/>
              - Network Administrator roles<br/>
              - Project Manager positions in IT<br/>
              </p>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>












    </>
  )
}

export default Home
